<template>
  <div
    class="fixed bottom-0 left-0 top-[300px] z-10 w-screen cursor-default bg-black opacity-50 hover:hidden"
    :class="{ hidden: isOverlayHidden }"
  />
  <div
    class="absolute left-0 right-0 z-[50] flex w-screen items-center justify-center bg-white shadow-lg"
  >
    <div
      id="single-brand"
      class="mt-[1px] grid min-h-[313px] w-full cursor-default items-center justify-center gap-y-4 whitespace-nowrap px-[-25%] pb-[40px] pt-[30px] md:grid-cols-3 lg:w-4/5 lg:grid-cols-4 xl:w-3/5"
    >
      <NuxtLink
        v-for="brand in brands"
        class="display-2 b-3d m-auto flex h-[130px] w-[156px] cursor-pointer items-center justify-center gap-4 rounded-[3px] bg-[#F5F5F5]"
        :key="brand.id"
        :to="{
          path: '/products',
          query: { brands: brand.id },
        }"
        @click="$parent?.$parent?.$emit('toggle', false)"
      >
        <div
          v-if="brand.logo != null && brand.logo != ''"
          class="flex items-center justify-center"
        >
          <CommonUImg
            class="h-2/4 w-3/4"
            :src="brand.logo"
            :alt="brand.title"
          />
        </div>
      </NuxtLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Brand } from "~/types/InformativePages/Header/CategoryBundleBrand";

defineProps({
  brands: Array as PropType<Brand[]>,
});

const isOverlayHidden = ref(false);

const handleScroll = () => {
  const scrollY = window.scrollY;
  const threshold = 100;
  isOverlayHidden.value = scrollY > threshold;
};

onMounted(() => {
  window.addEventListener("scroll", handleScroll);
});
</script>
