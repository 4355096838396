<template>
  <div
    v-if="category?.children?.length"
    :class="{ hidden: isOverlayHidden }"
    class="fixed bottom-0 left-0 top-[160px] z-10 w-screen cursor-default bg-black opacity-50 hover:hidden"
  ></div>
  <div
    v-if="category?.children?.length"
    class="absolute left-1/2 top-11 z-[20] w-[90%] max-w-screen-xl -translate-x-1/2 transform cursor-default gap-4 bg-white shadow-lg"
  >
    <div class="flex w-full justify-center">
      <div
        id="subcategory-list"
        class="grid w-full grid-cols-3 items-center gap-x-4 gap-y-8 px-8 pb-[56px] pt-[56px] md:gap-x-4 md:gap-y-8 lg:px-[20px] xl:gap-x-4 xl:gap-y-8 xl:px-[120px]"
      >
        <!-- Categories list view -->
        <template v-if="category?.children">
          <NuxtLink
            v-for="subcategory in category?.children"
            class="display-2 flex cursor-pointer items-center gap-4 hover:[color:--main]"
            :key="subcategory.id"
            :to="{
              path: paths.products.index,
              query: {
                sub_categories: subcategory?.id,
              },
            }"
            @click="$parent?.$parent?.$emit('toggle', category?.id || false)"
          >
            <CommonUImg
              class="h-[56px] w-[56px] rounded-lg"
              :src="subcategory?.category_image || ''"
              :alt="subcategory.title"
            />

            <span class="display-2">
              {{ subcategory.title }}
            </span>
          </NuxtLink>
          <NuxtLink
            class="display-2 flex cursor-pointer items-center gap-4 hover:[color:--main]"
            :to="{
              path: paths.products.index,
              query: { categories: category?.id },
            }"
            @click="$parent?.$parent?.$emit('toggle', category?.id || false)"
          >
            <CommonUImg
              class="h-[56px] w-[56px] rounded-lg"
              :src="category?.category_image || ''"
              :alt="category?.title"
            />
            {{ $t("informative.header.titles.view_all") }} {{ category?.title }}
          </NuxtLink>
        </template>

        <template v-else>
          <CommonSkeletonLoader
            class="h-[9rem] w-[10rem] bg-[#F5F5F5]"
            v-for="index in 6"
            :key="index"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import paths from "~/constants/paths";
import { Category } from "~/types/ProductManagement/Categories";

defineProps({
  category: Object as PropType<Category>,
});
const isOverlayHidden = ref(false);

const handleScroll = () => {
  const scrollY = window.scrollY;
  const threshold = 100;
  isOverlayHidden.value = scrollY > threshold;
};

onMounted(() => {
  window.addEventListener("scroll", handleScroll);
});
</script>
