<template>
  <div
    class="flex w-full flex-col-reverse justify-start md:flex-col md:justify-start"
  >
    <div class="social-wrapper">
      <div v-for="(link, index) in socialLinks" :key="index">
        <NuxtLink :to="link.content" target="_blank" rel="noopener noreferrer">
          <img :src="link.image" :alt="link.title" class="mr-4 h-8 w-8" />
        </NuxtLink>
      </div>
    </div>

    <div v-if="tax.length > 0" class="tax">
      {{ $t("footer.social_section.tax") }} {{ tax[0]?.content }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { useFooterMenu } from "~/composables/features/Footer/useFooterMenu";

const { tax, socialLinks } = useFooterMenu();
</script>
