<template>
  <div>
    <div @click="toggleHelp" class="footer-section md:hover:cursor-default">
      <div class="footer-header md:mb-3">
        {{ $t("common.messages.Help") }}
      </div>
      <IconsArrowUpIcon
        :class="{ 'rotate-[180deg]': !showHelp }"
        class="block transform md:hidden"
      />
    </div>
    <div :class="{ '!mt-0 h-0 md:h-full': !showHelp }" class="items-wrapper">
      <NuxtLink
        v-for="(page, index) in helpSectionPages"
        :key="index"
        :to="getPathName(page.url)"
        class="footer-item"
        >{{ page.title }}</NuxtLink
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Page } from "~/types/Footer/HelpPages";

defineProps({
  helpSectionPages: {
    type: Array as PropType<Page[]>,
    required: true,
  },
});

const showHelp = ref(false);

const getPathName = (url: string): string => new URL(url).pathname;

const toggleHelp = () => {
  showHelp.value = !showHelp.value;
};
</script>
