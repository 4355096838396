<template>
  <div>
    <!-- Overlay -->
    <div
      v-if="category?.children?.length"
      :class="{ hidden: isOverlayHidden }"
      class="fixed bottom-0 left-0 top-[157px] z-10 w-screen cursor-default bg-black opacity-50 hover:hidden"
    />

    <!-- Subcategory list -->
    <div
      v-if="category?.children?.length"
      class="absolute left-1/2 top-11 z-[20] w-[90%] max-w-screen-xl -translate-x-1/2 transform cursor-default gap-4 bg-white shadow-lg"
    >
      <div class="flex w-full justify-center">
        <div
          id="subcategory-list"
          :class="{
            'top-full flex flex-row flex-wrap items-center justify-center':
              category?.children?.length < 5,
            'grid grid-cols-4': category?.children?.length >= 5,
            'w-full cursor-default px-8 pb-[56px] pt-[56px] md:gap-x-4 md:gap-y-8 lg:px-[20px] xl:gap-x-4 xl:gap-y-8 xl:px-[120px]': true,
          }"
        >
          <!-- Categories list view -->
          <template v-if="$features?.categories">
            <NuxtLink
              v-for="subcategory in category?.children"
              class="display-2 flex cursor-pointer flex-col items-center justify-center gap-5 hover:[color:--main]"
              :key="subcategory.id"
              :to="{
                path: paths.products.index,
                query: {
                  sub_categories: subcategory?.id,
                },
              }"
              @click="$parent?.$parent?.$emit('toggle', category?.id || false)"
            >
              <div class="bg-[#F5F5F5]">
                <CommonUImg
                  class="h-[9rem] w-[10rem]"
                  :src="subcategory.category_image || ''"
                  :alt="subcategory.title"
                />
              </div>
              <span class="display-2 mt-2">
                {{ subcategory.title }}
              </span>
            </NuxtLink>
            <NuxtLink
              @click="$parent?.$emit('close')"
              :to="{
                path: paths.products.index,
                query: { categories: category?.id },
              }"
              class="mb-12 flex h-[9rem] w-[10rem] cursor-pointer flex-col items-center justify-center bg-[#F5F5F5] py-5"
            >
              <span class="h2 mx-[42px] block cursor-pointer text-center">
                {{ $t("informative.header.titles.view_all") }}
              </span>
            </NuxtLink>
          </template>

          <CommonSkeletonLoader
            v-else
            v-for="index in 6"
            class="h-[9rem] w-[10rem] bg-[#F5F5F5]"
            :key="index"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import paths from "~/constants/paths";
import { Category } from "~/types/ProductManagement/Categories";
defineProps({
  category: Object as PropType<Category>,
});

const isOverlayHidden = ref(false);

const handleScroll = () => {
  const scrollY = window.scrollY;
  const threshold = 50;
  isOverlayHidden.value = scrollY > threshold;
};

onMounted(() => {
  window.addEventListener("scroll", handleScroll);
});
</script>
