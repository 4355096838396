<template>
  <div
    class="absolute left-0 z-10 mt-[1px] whitespace-nowrap pb-4"
    v-if="category?.children?.length"
  >
    <div class="flex">
      <div class="bg-white pb-4">
        <NuxtLink
          v-for="subcategory in subCategories"
          class="display-2 flex cursor-pointer px-8 pb-4 pt-4 hover:bg-[#FBFBFB]"
          ref="subcategoryLinks"
          :to="{
            path: paths.products.index,
            query: {
              sub_categories: subcategory?.id,
            },
          }"
          :key="subcategory.id"
          :class="{ 'bg-[#FBFBFB]': activeSubcategory === subcategory.id }"
          @click="$parent?.$parent?.$emit('toggle', false)"
          @mouseenter="showSubcategoryChildren(subcategory.id)"
        >
          {{ subcategory.title }}
        </NuxtLink>
        <NuxtLink
          class="display-1 block cursor-pointer px-8 pt-4 font-bold hover:[color:--main]"
          :to="{
            path: paths.products.index,
            query: {
              categories: category?.id,
            },
          }"
          @click="$parent?.$parent?.$emit('toggle', category?.id || false)"
        >
          {{ $t("informative.header.titles.view_all") }} {{ category?.title }}
        </NuxtLink>
      </div>
      <div class="bg-[#FBFBFB]">
        <template v-for="subcategory in subCategories" :key="subcategory.id">
          <template v-if="subcategory.children">
            <template v-if="activeSubcategory === subcategory.id">
              <div
                v-for="collection in subcategory.children"
                :key="collection.id"
              >
                <NuxtLink
                  class="display-2 block cursor-pointer px-8 pt-4 hover:[color:--main]"
                  :to="{
                    path: paths.products.index,
                    query: {
                      collections: collection.id,
                    },
                  }"
                  @click="
                    $parent?.$parent?.$emit('toggle', category?.id || false)
                  "
                >
                  {{ collection.title }}
                </NuxtLink>
              </div>
            </template>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import paths from "~/constants/paths";
import { Category } from "~/types/ProductManagement/Categories";

const props = defineProps({
  category: Object as PropType<Category>,
});

const subCategories = props.category?.children;
const activeSubcategory = ref("");

const showSubcategoryChildren = (subcategoryId: string) => {
  activeSubcategory.value = subcategoryId;
};

onMounted(() => {
  if (subCategories && subCategories.length > 0) {
    const firstSubcategory = subCategories[0];
    activeSubcategory.value = firstSubcategory.id;
    showSubcategoryChildren(firstSubcategory.id);
  }
});
</script>
