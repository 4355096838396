<template>
  <div class="-mx-2 flex items-end">
    <div class="flex-1 px-2 py-4">
      <div v-for="letter in sortedLetters" :key="letter">
        <div class="contact-group">
          <div :id="`group-${letter}`" class="letter h4">
            {{ letter }}
          </div>
          <ul class="contact-list">
            <NuxtLink
              v-for="(address, index) in contactGroups[letter]"
              :key="address.title"
              :class="{
                'mb-2 border-none': index + 1 == contactGroups[letter].length,
              }"
              class="contact-item flex-start flex border-b-[0.5px] px-2 pb-2 pt-4"
              style="
                font-family: Lato;
                font-size: 14px;
                font-weight: 500;
                line-height: 17px;
                letter-spacing: 0em;
                text-align: center;
              "
              :to="{
                path: '/products',
                query: { brands: address.id },
              }"
              @click="$parent?.$parent?.$parent?.$emit('close')"
            >
              {{ address.name }}
            </NuxtLink>
          </ul>
        </div>
      </div>
    </div>

    <div class="sticky top-5" id="alphabet-list">
      <div
        v-for="letter in sortedLetters"
        :key="letter"
        class="alphabet-item h5 text-black"
        @click="scrollToGroup(letter)"
      >
        {{ letter }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type {
  FormattedAddress,
  Brand,
} from "~/types/InformativePages/Header/CategoryBundleBrand";

const props = defineProps({
  brands: {
    type: Array as PropType<Brand[]>,
    default: [],
  },
  layout: { type: Number, default: 1 },
});

const contactGroups = ref<Record<string, FormattedAddress[]>>({});
const sortedLetters = ref<string[]>([]);

const formattedAddresses = ref<FormattedAddress[]>([]);

const reFormatAddresses = () => {
  if (props.brands && props.brands.length > 0) {
    formattedAddresses.value = props.brands.map((brand) => ({
      name: brand.title,
      letter: brand.title.charAt(0).toLocaleUpperCase(),
      id: brand.id,
    }));

    sortContacts();
  }
};

const sortContacts = () => {
  if (formattedAddresses.value.length > 0) {
    sortedLetters.value = [
      ...new Set(formattedAddresses.value.map((address) => address.letter)),
    ].sort();

    sortedLetters.value.forEach((letter) => {
      contactGroups.value[letter] = formattedAddresses.value.filter(
        (address) => address.letter === letter,
      );
    });
  }
};

const scrollToGroup = (letter: string) => {
  const groupContainer = document.getElementById(`group-${letter}`);
  if (groupContainer) {
    window.scrollTo({
      top: 2000,
      behavior: "smooth",
    });
    groupContainer.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  }
};

onMounted(() => {
  reFormatAddresses();
});

watch(
  () => props.brands,
  (newBrands) => {
    if (newBrands && newBrands.length > 0) {
      reFormatAddresses();
    }
  },
  { immediate: true },
);
</script>
