<template>
  <li
    class="home-no-bullets relative z-[2] h-full transition"
    :class="
      $features?.categories?.screens?.categories_screen === '1' &&
      type === 'category'
        ? 'relative'
        : ''
    "
  >
    <div
      data-cy="categories"
      class="display-1 flex h-full w-[200px] cursor-pointer flex-col justify-center text-sm font-bold capitalize"
      :class="{ 'border-b border-main': showDropdown }"
      @mouseenter="showDropdown = true"
      @mouseleave="showDropdown = false"
    >
      <div class="z-4 flex h-full items-center px-5">
        {{ $t("common.titles.all_categories") }}
        <IconsArrowUpIcon
          class="transition-all"
          :class="showDropdown ? 'rotate-0' : '-rotate-180'"
        />
      </div>
      <div
        class="absolute left-[2] top-[2.8rem] z-10 mt-[1px] w-full whitespace-nowrap pb-4"
        v-if="showDropdown"
      >
        <div class="flex w-[700px]">
          <div class="w-[280px] bg-white pb-4">
            <button
              v-for="category in categories"
              class="display-2 flex cursor-pointer px-6 pt-4 hover:bg-[#FBFBFB]"
              :key="category.id"
              :class="{ 'bg-[#FBFBFB]': activeCategory === category.id }"
              @mouseenter="showCategoryChildren(category.id)"
              @click.prevent="handleCategoryClick(category.id)"
            >
              {{ category.title }}
            </button>
          </div>
          <div
            v-if="
              activeCategoryObject &&
              activeCategoryObject.children &&
              activeCategoryObject.children.length
            "
            class="w-1/2 bg-[#FBFBFB]"
          >
            <div class="px-8 pt-4 text-xl font-bold">
              {{ activeCategoryObject.title }}
            </div>
            <div
              v-for="subcategory in activeCategoryObject.children"
              :key="subcategory.id"
            >
              <button
                class="display-2 block cursor-pointer px-8 pt-4 hover:[color:--main]"
                @click.prevent="handleSubCategoryClick(subcategory.id)"
              >
                {{ subcategory.title }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </li>
</template>

<script setup lang="ts">
import paths from "~/constants/paths";
import { Category } from "~/types/ProductManagement/Categories";

const props = defineProps<{
  type: string;
  categories: Category[];
}>();

const showDropdown = ref(false);
const activeCategory = ref("");

const router = useRouter();

const showCategoryChildren = (subcategoryId: string) => {
  activeCategory.value = subcategoryId;
};

const activeCategoryObject = computed(() => {
  return props.categories.find(
    (category: any) => category.id === activeCategory.value,
  );
});

const handleCategoryClick = (categoryId: string) => {
  showDropdown.value = false;
  router.replace({
    path: paths.products.index,
    query: {
      categories: categoryId,
    },
  });
};

const handleSubCategoryClick = (subcategoryId: string) => {
  showDropdown.value = false;
  router.replace({
    path: paths.products.index,
    query: {
      sub_categories: subcategoryId,
    },
  });
};

onMounted(() => {
  const firstCategory = props.categories[0];
  activeCategory.value = firstCategory.id;
  showCategoryChildren(firstCategory.id);
});
</script>
