<template>
  <li
    class="home-no-bullets z-10 h-full transition"
    :class="
      $features?.categories?.screens?.categories_screen === '1' &&
      type === 'category'
        ? 'relative'
        : ''
    "
  >
    <div
      data-cy="categories"
      @mouseenter="emits('toggle', item.id || true)"
      @mouseleave="emits('toggle', item.id || false)"
      :class="{ 'border-b border-main': show }"
      class="display-1 flex h-full cursor-pointer flex-col justify-center text-sm font-bold capitalize"
    >
      <template v-if="type === 'category' && $features?.categories">
        <li>
          <NuxtLink
            class="z-4 flex h-full items-center px-5"
            :to="{
              path: '/products',
              query: { categories: item?.id },
            }"
            @click="$emit('toggle', item.id)"
          >
            {{ item.title }}
          </NuxtLink>
        </li>
        <CategoriesContainer v-if="show" :category="item" />
      </template>

      <template v-else-if="type === 'brands' && $features?.brands">
        <button class="z-4 relative flex h-full items-center px-5">
          {{ $t("informative.home.titles.brands") }}
        </button>

        <BrandContainer v-if="show" id="brands-container" :brands="item" />
      </template>

      <template v-else-if="type === 'bundles' && $features?.bundles">
        <NuxtLink
          class="z-4 flex h-full items-center px-5"
          :to="{
            path: `/bundles`,
          }"
          @click="$emit('toggle', item.id)"
        >
          {{ $t("bundles.titles.bundles") }}
        </NuxtLink>
      </template>
    </div>
  </li>
</template>

<script setup lang="ts">
import BrandContainer from "~/components/features/Header/MenuNav/BrandContainer.vue";
import CategoriesContainer from "~/components/features/Header/MenuNav/CategoriesContainer.vue";

defineProps(["item", "show", "type"]);
const emits = defineEmits(["toggle"]);
</script>
