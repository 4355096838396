<template>
  <footer>
    <div
      :class="{
        'main-version mt-16 w-full px-10 md:px-[3.125rem]': version === 'main',
      }"
    >
      <div
        v-if="version === 'main'"
        class="items-stretch justify-between md:flex"
      >
        <NuxtLink :to="{ name: 'index' }">
          <img
            class="mb-10 max-h-[65px] max-w-[170px] md:mb-0"
            v-if="$appConfig?.web_icon_url"
            :src="$appConfig.web_icon_url"
            alt="Logo"
          />
        </NuxtLink>
        <QuickLinks />
        <hr class="my-4 md:hidden" />
        <HelpSection :help-section-pages />
        <hr class="my-4 md:hidden" />
        <AboutSection />
        <div class="flex flex-col items-end md:mt-0 md:justify-between">
          <!-- <Lang class="left-auto pr-2 md:mb-6 md:block md:pr-0" /> -->
          <SocialSection />
        </div>
      </div>
      <div
        v-else
        :class="{
          'not-main-version': $features?.cart?.configs?.enableOnlinePayment,
          hidden: !$features?.cart?.configs?.enableOnlinePayment,
        }"
        class="flex items-center justify-center"
      >
        <PaymentSponsors />
      </div>
    </div>
    <CopyrightsSection :help-section-pages :version="version" />
  </footer>
</template>

<script setup lang="ts">
import HelpSection from "./HelpSection.vue";
import QuickLinks from "./QuickLinks.vue";
import AboutSection from "./AboutSection.vue";
import PaymentSponsors from "./PaymentSponsors.vue";
import SocialSection from "./SocialSection.vue";
import Lang from "../Header/Lang.vue";
import CopyrightsSection from "./CopyrightsSection.vue";
import { LayoutVersion } from "~/types/Common/LayoutVersion";
import { useHelpPages } from "~/composables/features/Footer/useHelpPages";
import type { Page } from "~/types/Footer/HelpPages";

defineProps({
  version: String as PropType<LayoutVersion>,
});

const { helpSectionPages } = useHelpPages();
</script>
